<template>
    <section id="about-us">
        <DecorationCurveLeft class="decoration-curve-rotate" />
        <SectionName text="Conoce más acerca de Clínica Novocel" class="d-flex justify-content-center" />
        <!-- <TitleSecodary class="text-center mt-4 title-secondary" ppalText="Traumatología y Medicina del Dolor con base científica"/> -->
        <div class="about-us-container">
            <div class="intro-text">
                <div>
                    <p class="subtitle"><span class="secondText">Traumatología y Medicina del Dolor con base científica</span></p> 
                </div>
                <div class="text">
                    <p class="bolder">
                        La primera y única clínica traumatológica de Viña del Mar con una dirección médica y científica.
                    </p>
                    <p>Somos un equipo experto en terapias traumatológicas. Nuestra dirección médica está a cargo del Traumatólogo Dr. Humberto Verdugo Marchesse y la dirección científica a cargo de Cinthia Briceño. Nuestro equipo ha liderado proyectos de investigación e innovación en materia de salud, y hemos implementado un proceso de seguimiento de nuestros pacientes lo que nos permite evidenciar la efectividad de nuestros tratamientos. Por todo esto, creemos que en Clínica Novocel encontrarás un diagnóstico correcto, un tratamiento adecuado a patología, y sobre todo una solución a tu dolor ;)</p>
                    <p>
                        ¡Descubre un nuevo estándar en salud!
                    </p>
                    <ButtonAction link="https://www.clinicanovocel.cl/" :targetBlank=true text="Conócenos aquí" class="big-size"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SectionName from '@/components/SectionName.vue';

import ButtonAction from '@/components/ButtonAction.vue';
import DecorationCurveLeft from '@/components/DecorationCurveLeft.vue';
/* import TitleSecodary from '@/components/TitleSecodary.vue'; */

export default {
    name: 'LandingAboutUs',
    components: {
        SectionName,
        ButtonAction,
        DecorationCurveLeft,
        /* TitleSecodary, */
    },
    props: {
    },
    data() {
        return {
        }
    },
}
</script>

<style scoped>
#about-us{
    margin-top: 6rem;
}

.decoration-curve-rotate {    
    display: none;
    
}
.intro-text{
    padding: 0 0 1rem 0;
    position: relative;
    z-index: 2;
}
.subtitle{
    color: var(--light-violet);
    font-size: 40px;
    line-height: 45px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 2rem;
    padding-top: 3rem;
}
.secondText{
    color: var(--dark-violet);
}
.bolder{
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
}
.about-us-container{
    margin-bottom: 1rem;
    min-width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.about-us-container:first-of-type{
    margin-top: 3rem;
}

/* .title-secondary{
    max-width: 960px;
    margin: auto;
} /*

/* RESPONSIVE */
@media (min-width:576px) {
    .subtitle{
        font-size: 50px;
        line-height: 55px;
        max-width: 80%;
    }
    .text{
        padding-left: 20%;
    }
}

@media (min-width:992px) {
    .about-us-container:first-of-type{
        margin-top: 1rem;
    }
    #about-us{
        margin-top: -200px;
    }
    .decoration-curve-rotate {  
        display: flex;
        position: relative;
        top: 575px;
        left: -75px;
    }
}

@media (min-width:1200px) {
    .about-us-container:last-of-type{
        justify-content: flex-end;
    }
    .subtitle{
        font-size: 60px;
        line-height: 62px;
        max-width: 50%;
    }
    .text{
        padding-left: 42%;
        padding-top: 0.5rem;
    }
    
}
@media (min-width:1500px) {
    .about-us-container{
        padding: 0 9rem;   
    }
}
</style>

<style>
.decoration-curve-rotate img{
    transform: rotate(180deg);
}
.big-size.action-button{   
    border-radius: 60px;
    height: 60px;
    width: 250px;
    margin-top: 40px;
}
.big-size.action-button a{
    font-size: 1.5rem;
}
</style>