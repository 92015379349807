import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './assets/css/novocel.css'
/* import router from "@/router/index.js"; */
import router from "@/router/indexLanding.js";

import { createApp } from 'vue'
/* import App from './App.vue'; */
import App from './LandingApp.vue';

const app = createApp(App);
app.use(router);
app.mount('#app');
export { app };