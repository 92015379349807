<template>
    <section id="pathologies">
        <div class="group-path">
            <CardPathologiesIntro 
            class="pathologie"
            pathologie="Servicios"
            descriptionTitle="Somos especialistas en traumatología y tratamiento del dolor"
            landing="true"
            /> 
            
            <CardPathologies 
            class="pathologie"
            pathologie="Diagnósticos"
            number="750"
            descriptionNumber="Pacientes"
            descriptionText="Más de 750 personas diagnosticadas por año en Clínica Novocel."
            />
            
            <CardPathologies 
            class="pathologie"
            pathologie="Experiencia"
            number="250"
            descriptionNumber="Pacientes"
            descriptionText="Más de 250 procedimientos realizados por año."
            />

            <CardPathologies 
            class="pathologie"
            pathologie="Eficacia"
            number="95%"
            descriptionNumber="Efectividad"
            descriptionText="La mayoría de nuestros pacientes resultan totalmente conforme con el procedimiento realizado."
            />

        </div>
    </section>
</template>

<script>
import CardPathologies from '@/components/CardPathologies.vue';
import CardPathologiesIntro from '@/components/CardPathologiesIntro.vue';

export default {
    name: 'LandingPathologies',
    components: {
        CardPathologies,
        CardPathologiesIntro,
    },
}
</script>

<style scoped>
#pathologies{
    margin-top: 4.5rem;
}
#pathologies .group-path{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: stretch;
    max-width: 1400px;
    margin: auto;
}
#pathologies .group-path:last-of-type{
    margin-top: 20px;
    /* flex-direction: row-reverse; */
}
.pathologie{
    flex: 1 0 100% ;
}
.pathologie-presentation{
    hyphens: auto;
    word-wrap: break-word;
}
/* RESPONSIVE */
@media (min-width:992px) {
    .pathologie{
        flex: 0 0 calc(50% - 10px);
    }
    .pathologie.pathologie-presentation{
        flex: 1 0 100%;
    }
    .pathologie-presentation{
        hyphens: none;
        word-wrap: unset;
    }
}
@media (min-width:1200px) {
    .pathologie{
        flex: 1 1 calc(25% - 15px);
    }
    .pathologie.pathologie-presentation{
        flex: 1 1 calc(50% - 10px);
    }
}
</style>