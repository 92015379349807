<template>
    <section id="treatments" ref="mainElement">
        <SectionName text="Procedimientos traumatológicos" class="d-flex justify-content-center" />
        <TitleSecodary class="text-center mt-4" ppalText="Patologías tratables"/>
        <div class="text" ref="textElement">
            <p>
                Los procedimientos traumatológicos que entregamos a nuestros pacientes, sustentados científicamente, atienden a un conjunto de patologías como: artrosis de rodilla, artrosis de cadera, lesiones de ligamento cruzado anterior, condromalacia, inestabilidad de rodilla, y otros tipo de lesiones musculo-esqueléticas.
            </p>
        </div>

        <DecorationCurveDouble class="decoration-curve" v-if="decorationCurve" />
        
        <div :class="{'cards-treatments': decorationCurve}" :style="{ marginTop: `${calculatedMarginTop}px` }">
            <div class="cards-treatments-container" >
                <CardTreatmentLanding 
                class="card-item" 
                :background=background
                :icon="Artrosis" 
                altIcon="Ícono de artrosis" 
                title="Artrosis"
                />

                <CardTreatmentLanding 
                class="card-item" 
                :background=background
                :icon="Condropatias" 
                altIcon="Ícono de Condropatias" 
                title="Condropatías"
                />

                <CardTreatmentLanding 
                class="card-item" 
                :background=background
                :icon="Lesiones" 
                altIcon="Ícono de Lesiones" 
                title="Lesiones deportivas"
                />
            </div>
            
        </div>
    </section>
</template>

<script>
import SectionName from '@/components/SectionName.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';
import DecorationCurveDouble from '@/components/DecorationCurveDouble.vue';
import CardTreatmentLanding from '@/components/CardTreatmentLanding.vue';

import Artrosis from '@/assets/img/icons/webp/inflamacion.webp';
import Condropatias from '@/assets/img/icons/webp/cartílago.webp';
import Lesiones from '@/assets/img/icons/webp/movilidad.webp';

export default {
    name: 'LandingTreatments',
    components: {
        SectionName,
        TitleSecodary,
        DecorationCurveDouble,
        CardTreatmentLanding,
    },
    props: {
        decorationCurve: {
            type: Boolean,
            default: true,
        },
        background: {
            type: Boolean,
        }
    },
    data() {
        return {
            Artrosis, Condropatias, Lesiones,
            /* calculatedMarginTop: 0,
            width: 0,
            textHeight: 0, */
        }
    },
    /* methods: {
        calculateMarginTop() {
            const width = this.$refs.mainElement.clientWidth;
            const textHeight = this.$refs.textElement.clientHeight;
            
            this.calculatedMarginTop = (-100 * (width / window.innerWidth)) + (1088 / 579) * textHeight - 107306 / 193;

            console.log(width)
            console.log(window.innerWidth)
            console.log(textHeight)
            

        },
        updateDimensions() {
            this.width = this.$refs.mainElement.clientWidth;
            this.textHeight = this.$refs.textElement.clientHeight;
            this.calculateMarginTop();
        },
    },
    mounted() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);        
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }, */
}
</script>

<style scoped>
#treatments{
    margin-top: 4rem;
}
.decoration-curve{
    position: relative;
    top: -310px;
    display: flex;
    justify-content: center;
}
.cards-treatments{
    max-width: 1400px;
    margin: auto;
    margin-top: -180px;
}
ul.card-description{
    list-style-type: "- ";
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.5rem;
    padding-left: 0.9rem;
    margin-bottom: 0;
}
.cards-treatments-container:first-of-type{
    margin-top: 3rem;
}
.no-decoration{
    margin-top: 20px;
}
.text{
    padding-top: 1.5rem;
    max-width: 1200px;
    text-align: center;
    margin: auto;
}
.card-item:nth-of-type(1){
    grid-area: cardOne;
}
.card-item:nth-of-type(2){
    grid-area: cardTwo;
}
.card-item:nth-of-type(3){
    grid-area: cardThree;
}
.cards-treatments-container{
    min-width: 100%;
    display: grid;
    justify-content: center;
    gap: 20px;
    grid-template-areas: 'cardOne' 'cardTwo' 'cardThree';
    margin-bottom: 1rem;
}
/* RESPONSIVE */
@media (min-width:378px) {
    .cards-treatments{
        margin-top: -200px;
    }
}

@media (min-width:400px) {
    .cards-treatments{
        margin-top: -220px;
    }
}

@media (min-width:460px) {
    .cards-treatments{
        margin-top: -240px;
    }
}

@media (min-width:480px) {
    .cards-treatments{
        margin-top: -260px;
    }
}

@media (min-width:520px) {
    .cards-treatments{
        margin-top: -280px;
    }
}

@media (min-width:560px) {
    .cards-treatments{
        margin-top: -300px;
    }
}

@media (min-width:600px) {
    .cards-treatments{
        margin-top: -320px;
    }
}

@media (min-width:630px) {
    .cards-treatments{
        margin-top: -340px;
    }
}

@media (min-width:690px) {
    .cards-treatments{
        margin-top: -370px;
    }
}

@media (min-width:730px) {
    .cards-treatments{
        margin-top: -410px;
    }
}

@media (min-width:768px) {
    .cards-treatments{
        margin-top: -330px; /* calc(-100px - 30vw); */
    }
}

@media (min-width:860px) {
    .cards-treatments{
        margin-top: -360px;
    }
}

@media (min-width:900px) {
    .cards-treatments{
        margin-top: -390px;
    }
}

@media (min-width:970px) {
    .cards-treatments{
        margin-top: -430px;
    }
}

@media (min-width:990px) {
    .cards-treatments{
        margin-top: -390px;
    }
}

@media (min-width:1100px) {
    .cards-treatments{
        margin-top: -420px;
    }
}

@media (min-width:1160px) {
    .cards-treatments{
        margin-top: -450px;
    }
}

@media (min-width:1200px) {
    .cards-treatments{
        margin-top: -470px;
    }
}

@media (min-width:1260px) {
    .cards-treatments{
        margin-top: -510px;
    }
}

@media (min-width:1360px) {
    .cards-treatments{
        margin-top: -550px;
    }
}

@media (min-width:1450px) {
    .cards-treatments{
        margin-top: -590px;
    }
}

@media (min-width:768px) {
    .cards-treatments-container{
        grid-template-areas:    'cardOne cardTwo' 
                                'cardThree cardThree';
    }
}

@media (min-width:992px) {
    .cards-treatments-container:first-of-type{
        margin-top: 1rem;
    }
    .card-item{
        width: 350px;
    }
    .card-item:nth-of-type(3){
        width: auto;
    }
}

@media (min-width:1200px) {
    .card-item{
        max-width: 475px;
    }
    .cards-treatments-container{
        grid-template-areas:    'cardOne cardTwo cardThree';
    }
}

</style>