<template>
    <section id="presentation">
        <DecorationCurveLeft class="decoration-curve-left" />
        <div>
            <TitlePrincipal class="col-12 col-lg-7 col-xl-7 title" ppalText="Disminuye el dolor, la inflamación y recupera tu movilidad con nuestras terapias traumatológicas" />
            <ButtonAction link="https://www.clinicanovocel.cl/" :targetBlank=true text="Conócenos aquí" class="big-size"/>
        </div>
        <div class="information">
            <div class="contact-form col-12 col-lg-5 col-xl-5">
                <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/6rG1uq6n62kITC2hiD9uQQ1WtPlgIThiEkLrlvASv0aSeWcl3YTTOG8ysG5fLyzEe7">
            </div>
        </div>
        </div>
    </section>
</template>

<script>
import TitlePrincipal from '@/components/TitlePrincipal.vue';
import DecorationCurveLeft from '@/components/DecorationCurveLeft.vue';
import ButtonAction from '@/components/ButtonAction.vue';

export default {
    name: 'SectionPresentation',
    components: {
        TitlePrincipal,
        DecorationCurveLeft,
        ButtonAction
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth < 1500;
        }
    },
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://webforms.pipedrive.com/f/loader';
        document.head.appendChild(script);
        
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped>
#presentation {
    width: 100%;
}
.decoration-curve-left {
    position: absolute;
    right: -80px;
    top: 200px;
    transform: scale(0.80);
}
.title {
    position: relative;
    z-index: 2;
    padding-top: 55px;
    width: calc(100% - 2rem);
}
.schedule {
    min-width: 200px;
}
.contact-form{
    background-color: var(--white);
    border-radius: 1rem;
    padding: 20px;
    margin-bottom: 1rem;
    min-height: 450px;
    position: relative;
    z-index: 1;
    margin-top: 50px;
}

/* RESPONSIVE */
@media (min-width:768px) {
    .decoration-curve-left {
        top: 120px;
        right: -45px;
        transform: initial;
        
    }
    .title{
        font-size: 65px;
        line-height: 67px;
    }
}
/* RESPONSIVE */
@media (min-width:992px) {
    #presentation {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
    }
    .contact-form{
        width: 400px;
    }
    .decoration-curve-left {
        /* right: -75px; */        
        right: calc(100vw - 490px);
        top: 480px;
        /* bottom: 150px; */
        transform: rotate(180deg);
    }
    .description-text {
        padding-left: 2rem;
    }
    .information {
        top: -152px;
        display: flex;
        justify-content: flex-end;
    }
    .title{
        margin-top: 35px;
    }
}
/* RESPONSIVE */
@media (min-width: 1380px){
    #presentation{
        align-items: center;
    }
    .title{
        font-size: 80px;
        line-height: 82px;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 50px;
    }
    .contact-form{
        width: 550px;
    }
}
</style>